
.custom-player {
    font-family: 'Oswald', sans-serif !important;
  }


  
 /* Styling for the play button when the player is paused */
.custom-player.vjs-paused .vjs-play-control:before {
    content: 'play_arrow' !important; 
    font-family: 'Material Icons' !important;
    font-size: 20px !important; /* Adjust size as needed */
    color: rgb(207, 207, 207) !important;
}

.custom-player.vjs-playing .vjs-play-control:before {
    content: 'pause' !important;
    font-family: 'Material Icons' !important;
    font-size: 20px !important; /* Adjust size as needed */
    color: rgb(207, 207, 207) !important;
}
/* Styling for the volume button when volume is not muted (volume up) */
.custom-player .vjs-mute-control:before {
    content: 'volume_up' !important;
    font-family: 'Material Icons' !important;
    font-size: 20px !important; /* Adjust size as needed */
    color: rgb(207, 207, 207) !important;
}

/* Styling for the volume button when muted */
.custom-player .vjs-mute-control.vjs-vol-0:before {
    content: 'volume_off' !important;
    font-family: 'Material Icons' !important;
    font-size: 20px !important; /* Adjust size as needed */
    color: rgb(207, 207, 207) !important;
}

/* Hide the original SVG or icon placeholder */
.custom-player .vjs-mute-control .vjs-icon-placeholder {
    display: none !important;
}

/* Additional styling for the mute/unmute button itself */
.custom-player .vjs-mute-control {
    background: none !important;  /* Remove any background */
    border: none !important;      /* Remove any borders */
    transition: transform 0.2s ease-in-out;
}

/* Optional hover effect for the mute/unmute button */
.custom-player .vjs-mute-control:hover {
    transform: scale(1.1);  /* Slightly enlarge on hover */
}


/* Hide the original SVG or icon placeholder */
.custom-player .vjs-play-control .vjs-icon-placeholder {
    display: none !important;
}

/* Additional styling for the play/pause button itself */
.custom-player .vjs-play-control {
    background: none !important;  /* Remove any background */
    border: none !important;      /* Remove any borders */
    transition: transform 0.2s ease-in-out;
}

/* Optional hover effect for the play/pause button */
.custom-player .vjs-play-control:hover {
    transform: scale(1.1);  /* Slightly enlarge on hover */
}
  
  /* Apply font to specific components for more granular control */
  .custom-player .vjs-control-bar,
  .custom-player .vjs-big-play-button,
  .custom-player .vjs-menu-item,
  .custom-player .vjs-volume-menu-button,
  .custom-player .vjs-playback-rate {
    font-family: 'Oswald', sans-serif !important;
  }

.custom-player .vjs-big-play-button {
    box-sizing: border-box !important;  /* Include border in element size */
    background-color: #282828 !important;
    border-radius: 5px !important;
    border: 1px solid #444 !important;
    transition: none !important; /* Prevent smooth changes */
}

/* Ensure the styling stays consistent across hover, focus, and active states */
.custom-player .vjs-big-play-button:hover,
.custom-player .vjs-big-play-button:focus,
.custom-player .vjs-big-play-button:active {
    background-color: #282828 !important;
    border-radius: 5px !important;
    border: 1px solid #818181 !important;
    transition: none !important;
}

/* Disable any internal Video.js styles for the play button */
.custom-player .vjs-big-play-button.vjs-hidden {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
}

/* Target the hover state of container elements */
.custom-player .vjs-control:hover .vjs-big-play-button,
.custom-player .vjs-poster:hover + .vjs-big-play-button {
    background-color: #282828 !important;
    border-radius: 5px !important;
    border: 1px solid #444 !important;
    transition: none !important;
}

.custom-player .vjs-poster img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* border: solid 2px; */
    /* border-color: #ff0000; */
    outline: 2px solid #191919;
    outline-offset: -2px;
}


.custom-player .vjs-play-progress:before {
    display:none;
}


.custom-player .vjs-control-bar {
    background-color: rgba(43, 43, 43, 1.0) !important;
  }

  .custom-player .vjs-slider {
    background-color: rgba(106, 106, 106, 1.0) !important;
  }

  .custom-player .video-js .vjs-big-play-button {
    line-height: inherit;
    /* height: 3em; */ /* you can set a new height */

    margin-top: unset;
    margin-left: unset;
    transform: translate(-50%, -50%); 
}
.custom-player .video-js .vjs-big-play-button .vjs-icon-placeholder::before {
    position: inherit;
    top: unset;
    left: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}




  
  .custom-player .vjs-control-bar {
    height: 30px;
    background-color: hsla(0, 0%, 21%, 0.4);
  }
  
  .custom-player .vjs-button:hover {
    color: #4176bc;
    background: linear-gradient(0deg,#d0ddee,#fff);
  }
  
  .custom-player .vjs-button > .vjs-icon-placeholder:before {
    line-height: 30px;
  }
  
  .custom-player .vjs-time-control {
    line-height: 30px;
  }
  
  .custom-player .vjs-picture-in-picture-control
  {
    display: none;
  }
  
  .custom-player .vjs-progress-control .vjs-play-progress {
    background-color: rgba(241, 241, 241, 0.9);
  }
  
  .custom-player .vjs-progress-control .vjs-play-progress:before {
    display: none;
  }
  
  .custom-player .vjs-progress-control .vjs-slider {
    background-color: rgba(65,118,188,.1);
  }
  
  .custom-player .vjs-progress-control .vjs-load-progress div {
    background: hsla(0, 0%, 44%, 0.5);
  }
  
  .custom-player .vjs-progress-control .vjs-progress-holder {
    margin: 0;
    height: 40%;
  }
  
  .custom-player .vjs-progress-control .vjs-time-tooltip {
    background-color: rgba(188, 65, 165, 0.5);
    color: #fff;
  }
  
  .custom-player .vjs-progress-control .vjs-mouse-display .vjs-time-tooltip {
    background-color: hsla(0,0%,100%,.7);
    color: #000000;
  }


  .custom-player .vjs-layout-large .vjs-menu-button-popup .vjs-menu .vjs-menu-content, .vjs-layout-x-large .vjs-menu-button-popup .vjs-menu .vjs-menu-content, .vjs-layout-huge .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    /* max-height: 25em; */
    margin-bottom: 5px;
}


.custom-player .vjs-layout-medium .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    margin-bottom: 2px;
}

.custom-player .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    background-color: #2B333F;
    background-color: rgb(42 42 42);
    position: absolute;
    width: 100%;
    bottom: 2.0em;
}


.custom-player.vjs-volume-panel {
    display: flex;
    justify-content: center;
}

.custom-player.vjs-control {
    position: relative;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 40px;
    flex: none;
}




